<template>
  <div class="file-manager-application">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-area-wrapper container-xxl p-0">
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="sidebar-file-manager" :class="showMenu ? 'show' : null">
            <div class="sidebar-inner">
              <div class="sidebar-list">
                <div class="list-group cc-list-group">
                  <div class="my-drive" />
                  <b-link
                    class="list-group-item list-group-item-action d-flex justify-content-start align-items-center"
                    :to="{ name: 'admin.assignor.economicGroup.companies' }"
                    active-class="active"
                  >
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="currentColor"
                      class="mr-1"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.9561 5.00008H14.4561V4.16675C14.4561 2.75008 13.3728 1.66675 11.9561 1.66675H8.62277C7.2061 1.66675 6.12277 2.75008 6.12277 4.16675V5.00008H3.62277C2.20611 5.00008 1.12277 6.08342 1.12277 7.50008V15.8334C1.12277 17.2501 2.20611 18.3334 3.62277 18.3334H16.9561C18.3728 18.3334 19.4561 17.2501 19.4561 15.8334V7.50008C19.4561 6.08342 18.3728 5.00008 16.9561 5.00008ZM7.78944 4.16675C7.78944 3.66675 8.12277 3.33341 8.62277 3.33341H11.9561C12.4561 3.33341 12.7894 3.66675 12.7894 4.16675V5.00008H7.78944V4.16675ZM12.7894 16.6667V6.66675H7.78944V16.6667H12.7894ZM2.78944 15.8334V7.50008C2.78944 7.00008 3.12277 6.66675 3.62277 6.66675H6.12277V16.6667H3.62277C3.12277 16.6667 2.78944 16.3334 2.78944 15.8334ZM16.9561 16.6667C17.4561 16.6667 17.7894 16.3334 17.7894 15.8334V7.50008C17.7894 7.00008 17.4561 6.66675 16.9561 6.66675H14.4561V16.6667H16.9561Z"
                      />
                    </svg>

                    Empresas
                  </b-link>
                  <b-link
                    class="list-group-item list-group-item-action d-flex justify-content-start align-items-center"
                    :to="{ name: 'admin.assignor.economicGroup.users' }"
                    active-class="active"
                  >
                    <feather-icon icon="UsersIcon" size="16" class="mr-1" />
                    Usuários
                  </b-link>
                  <b-link
                    class="list-group-item list-group-item-action d-flex justify-content-start align-items-center"
                    :to="{ name: 'admin.assignor.economicGroup.invites' }"
                    active-class="active"
                  >
                    <feather-icon icon="UserCheckIcon" size="16" class="mr-1" />
                    Convites
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="content-wrapper container-xxl p-0">
          <div class="body-content-overlay" :class="showMenu ? 'show' : null" @click="hideMenu" />
          <div class="content-body">
            <div class="file-manager-main-content">
              <!-- <div class="file-manager-content-header w-100">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="sidebar-toggle d-block d-xl-none float-left align-middle ml-1">
                    <feather-icon
                      icon="MenuIcon"
                      size="26"
                      class="font-medium-5"
                      @click="showMenu = !showMenu"
                    />
                  </div>
                </div>
              </div> -->
              <div class="file-manager-content-body h-100">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BLink,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    allFiles() {
      const { representantes_legais } = this.currentAssignor
      let urls = [this.currentAssignor.contrato_social?.url]
      representantes_legais.map((rl) => {
        urls.push(rl.documento_identidade?.url)
        urls.push(rl.comprovante_residencia?.url)
        urls.push(rl.procuracao?.url)
        return rl
      })
      urls = urls.filter((item) => Boolean(item))
      return urls
    },
    currentAssignor() {
      return this.$store.state.cedente.current
    },
  },
  watch: {
    $route() {
      this.hideMenu()
    },
  },
  methods: {
    hideMenu() {
      this.showMenu = false
    },
    downloadFile(url) {
      const link = document.createElement('a')
      const filename = url.split('?')[0].split('/').pop()
      link.href = url
      link.setAttribute('target', '_blank')
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    downloadAll() {
      this.allFiles.map((item) => this.downloadFile(item))
    },
  },
}
</script>

<style lang="scss">
.content-area-wrapper {
  height: auto !important;
}

.cc-list-group {
  .list-group-item {
    padding: 0.6rem 1.3rem !important;
    border: 0;
    border-radius: 0;
  }
}
</style>
